<template>
  <div class="hotBusiness bgFFF paddingT20 paddingB20">
    <div class="searchWrapper">
      <el-form
        label-position="left"
        ref="form"
        :rules="rules"
        label-width="125px"
        :model="form"
        class="demo-form-inline"
      >
        <el-form-item label="热门地区名称：" class="area" prop="businessCenterName">
          <el-input v-model="form.businessCenterName" placeholder="请输入热门地区名称"></el-input>
        </el-form-item>
        <el-form-item label="包含城市：" prop="citys">
          <el-transfer
            target-order="push"
            filterable
            :titles="['未选择', '已选择']"
            filter-placeholder="请输入城市"
            :props="{ key: 'areaId', label: 'areaName' }"
            @change="handlechange"
            v-model="form.citys"
            :data="cityList"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="热门商圈范围以及车场：" class="parkList" prop="parkList">
          <area-map
            ref="areaMap"
            v-if="isShow"
            :paths="pathList"
            :editCenter="editCenter"
            :parks="parkIds"
            :type="type"
            @setPathList="setPathList"
            @setParkIds="setParkIds"
          ></area-map>
        </el-form-item>
        <el-form-item class="btnWrapper">
          <el-button type="primary" @click="submit">提交</el-button>
          <el-button type="info" @click="$router.go(-1)">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import areaMap from "./map";
export default {
  components: {
    areaMap,
  },
  data() {
    var validateParkList = (rule, value, callback) => {
      if (this.pathList.length <= 0 || this.parkIds.length <= 0) {
        callback(new Error("请选择热门商圈范围以及车场"));
      } else {
        callback();
      }
    };
    return {
      form: {
        businessCenterName: "",
        citys: [],
      },
      parkNameValue: "",
      loading: false,
      cityList: [],
      rules: {
        businessCenterName: [
          {
            required: true,
            message: "请输入热门地区名称",
            trigger: "blur",
          },
        ],
        citys: [
          {
            type: "array",
            required: true,
            message: "请选择城市",
            trigger: "change",
          },
        ],
        parkList: [
          {
            validator: validateParkList,
            trigger: "change",
          },
        ],
      },
      pathList: [],
      parkIds: [],
      type: "",
      isShow: false,
      editCenter: [116.322056, 39.89491], // 编辑时自动获取地图中心
    };
  },
  methods: {
    handlechange(v) {
      this.cityList.forEach((item, i) => {
        if (item.areaId == v[0]) {
          this.$refs.areaMap.map.setCenter([item.longitude / 1000000, item.latitude / 1000000]);
        }
      });
    },
    setPathList(v) {
      this.pathList = v;
    },
    setParkIds(v) {
      this.parkIds = v;
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.cityList = res.value.areaList;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let url =
            this.type != "edit"
              ? "/acb/2.0/businessCenter/create"
              : "/acb/2.0/businessCenter/update";
          let data =
            this.type != "edit"
              ? {
                  areaIds: this.form.citys.join(),
                  businessCenterMark: this.pathList.join(),
                  businessCenterName: this.form.businessCenterName,
                  parkIds: this.parkIds.join(),
                }
              : {
                  areaIds: this.form.citys.join(),
                  businessCenterId: this.businessCenterId,
                  businessCenterMark: this.pathList.join(),
                  businessCenterName: this.form.businessCenterName,
                  parkIds: this.parkIds.join(),
                };
          this.$axios
            .post(
              url,
              {
                data: data,
              },
              {
                headers: {
                  "Content-Type": "application/json;",
                },
              }
            )
            .then((res) => {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              if (res.state == 0) {
                this.$router.go(-1);
              }
            });
        }
      });
    },
    getDetails(id) {
      let url = "/acb/2.0/businessCenter/detail";
      this.$axios
        .get(url, {
          data: {
            businessCenterId: id,
          },
        })
        .then((res) => {
          let data = res.value;
          this.form.businessCenterName = data.businessCenterName;
          this.businessCenterId = data.businessCenterId;
          this.form.citys = data.araelist.map((item) => {
            return item.areaId;
          });
          this.cityList.forEach((item, i) => {
            if (item.areaId == this.form.citys[0]) {
              this.editCenter = [item.longitude / 1000000, item.latitude / 1000000];
            }
          });
          let marks = data.businessCenterMark.split(",");
          for (var i = 0; i < marks.length; i += 2) {
            this.pathList.push(marks.slice(i, i + 2));
          }
          this.parkIds = data.parkList.map((item) => {
            return item.parkId;
          });
          this.isShow = true;
        });
    },
  },
  mounted() {
    this.getAreaList();
    let data = this.$route.query;
    this.type = data.type;
    if (data.type == "edit") {
      setTimeout(() => {
        this.getDetails(data.id);
      }, 100);
    } else {
      this.isShow = true;
    }
  },
};
</script>

<style lang="stylus" scoped>
.hotBusiness {
  .searchWrapper {
    padding 20px
    .demo-form-inline {
      .area {
        width 380px
      }
      .el-form-item {
        height auto
      }
      .parkList >>> .el-form-item__label {
        width 180px
        float none
        text-align left
        &:before {
          content: '*';
          color: #F56C6C;
          margin-right: 4px;
        }
      }
      .btnWrapper {
        text-align center
      }
    }
  }
}
.hotBusiness >>> .el-transfer__buttons .el-button{
  display block;
  width 32px;
  height 32px;
  padding 0px;
  margin-right 0px!important
}
</style>
