<template>
  <div class="mapWrapper">
    <div class="search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <!-- :areaIds='formInline.streetId ? formInline.streetId : formInline.areaId' :operationId='formInline.operationId' -->
          <my-component
            ref="parkInput"
            @valueChange="completeValue"
            slaveRelations="0,1"
            :parkNameValue="parkNameValue"
            suffix-icon="el-icon-search"
          >
          </my-component>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ $t('button.search') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="map">
      <!-- <el-amap vid="amapDemo" :center="center" :events="events" :amap-manager="amapManager"
        :zoom="zoom" class="amap-demo">
      </el-amap> -->
      <div class="hotMap" id="map"></div>
    </div>
    <div class="tool">
      <el-button type="primary" size="mini" :disabled="isClear" @click="clear">{{ $t('button.reset') }}</el-button>
      <el-button type="primary" size="mini" :disabled="isStartEdit" @click="startEdit"
        >开始编辑
      </el-button>
      <el-button type="primary" size="mini" :disabled="isEndEdit" @click="endEdit"
        >结束{{ $t('button.edit') }}</el-button
      >
    </div>
  </div>
</template>
<script>
import myComponent from "@/components/autocomplete/myautoComponent";
// import {
//   AMapManager
// } from 'vue-amap';
// let amapManager = new AMapManager();

export default {
  components: {
    myComponent,
  },
  props: {
    paths: {
      type: Array,
      default: [],
    },
    parks: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "",
    },
    editCenter: {
      type: Array,
      default: [116.322056, 39.89491],
    },
  },
  data() {
    return {
      formInline: {
        parkId: "",
        parkName: "",
      },
      parkNameValue: "",
      center: [],
      map: null,
      polygon: null,
      polyEditor: null,
      massMarks: null,
      pathList: [],
      parkList: [],
      parkIds: [],
      parksInProp: [],
      isClear: true,
      isStartEdit: true,
      isEndEdit: true,
      isDraw: false,
      isEditDraw: false,
      style: [
        {
          url: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          anchor: new AMap.Pixel(6, 6),
          size: new AMap.Size(25.5, 35),
        },
        {
          url: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png",
          anchor: new AMap.Pixel(6, 6),
          size: new AMap.Size(25.5, 35),
        },
      ],
    };
  },
  methods: {
    completeValue(item) {
      this.center = [item.longitude / 1000000, item.latitude / 1000000];
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    areaMapInit() {
      let that = this;
      if (that.isDraw) {
        this.addMarkerClick();
        return;
      }
      that.map.plugin(["AMap.MouseTool"], (e) => {
        var mousetool = new AMap.MouseTool(that.map);
        that.isDraw = true;
        mousetool.polygon(); // 使用鼠标工具，在地图上画标记点
        mousetool.on("draw", function (e) {
          let pathList = e.obj.getPath();
          mousetool.close(true);
          that.polygon = new AMap.Polygon({
            path: pathList,
            strokeColor: "#FF33FF",
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillOpacity: 0.4,
            fillColor: "#1791fc",
            zIndex: 50,
          });
          that.map.add(that.polygon);
          // 缩放地图到合适的视野级别
          that.map.setFitView([that.polygon]);
          that.isClear = false;
          that.isEndEdit = false;
          that.polyEditor = new AMap.PolyEditor(that.map, that.polygon);
          // this.polyEditor.on('addnode', function (event) {
          //   // console.log('触发事件：addnode')
          // })
          that.polyEditor.open();
          that.addMarkerClick();
          // that.getPark()
        });
      });
    },
    addMarkerClick() {
      let that = this;
      that.massMarks.on("click", function (e) {
        console.log(e);
        if (e.data.style == 1) {
          e.data.style = 0;
          // that.$set(e.data, 'style', 0)
          that.parkIds.splice(that.parkIds.indexOf(e.data.id), 1);
          that.parksInProp.splice(that.parksInProp.indexOf(e.data.id), 1);
        } else {
          e.data.style = 1;
          // that.$set(e.data, 'style', 1)
          that.parkIds.push(e.data.id);
        }
        that.massMarks.setStyle(that.style); // 必须加 不然海量点跟多边形区域冲突 不能实时更新图标，需要拖动地图
        that.$emit("setParkIds", that.parkIds);
      });
    },
    onSubmit() {
      this.map.setCenter(this.center);
      // this.getPark(Math.round(this.map.getCenter().lng * 1000000), Math.round(this.map.getCenter()
      //   .lat * 1000000))
    },
    clear() {
      this.polyEditor.close();
      this.polygon.setMap("");
      this.massMarks.on("click");
      this.massMarks.clear();
      this.isStartEdit = true;
      this.isClear = true;
      this.isEndEdit = true;
      this.isDraw = false;
      this.getPark(
        Math.round(this.map.getCenter().lng * 1000000),
        Math.round(this.map.getCenter().lat * 1000000)
      );
      // this.areaMapInit();
    },
    startEdit() {
      this.isStartEdit = true;
      this.isEndEdit = false;
      this.polyEditor.open();
    },
    endEdit() {
      const that = this;
      that.isStartEdit = false;
      that.isEndEdit = true;
      that.polyEditor.on("end", function (event) {
        that.pathList = [];
        that.pathList = event.target.getPath();
        that.$emit("setPathList", that.pathList);
      });
      that.polyEditor.close();
    },
    getPark(longitude, latitude, isInit) {
      // this.parkIds = [];
      let url = "/acb/2.0/map/getHostParkByPermission";
      this.$axios
        .get(url, {
          data: {
            latitude: latitude,
            longitude: longitude,
            radius: 10000,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkList = res.value.map((item) => {
              return {
                lnglat: [item.longitude / 1000000, item.latitude / 1000000], // 点标记位置
                name: item.parkName,
                id: item.parkId,
                style: 0,
              };
            });
            // if (this.type == 'edit') {
            //   this.parkList.forEach(e => {
            //     this.parksInProp.forEach(item => {
            //       if (e.id == item) {
            //         e.style = 1
            //       }
            //     })
            //   });
            // } else {
            this.parkList.forEach((e) => {
              this.parkIds.forEach((item) => {
                if (e.id == item) {
                  e.style = 1;
                }
              });
            });
            // }
            this.massMarks = new AMap.MassMarks(this.parkList, {
              opacity: 0.8,
              zIndex: 111,
              cursor: "pointer",
              // alwaysRender: true,
              style: this.style, // 设置样式对象
            });
            var marker = new AMap.Marker({ content: " ", map: this.map });
            // 鼠标移入
            this.massMarks.on("mouseover", function (e) {
              marker.setPosition(e.data.lnglat);
              marker.setLabel({
                offset: new AMap.Pixel(-20, -18),
                content: `<div class='info'>${e.data.name}</div>`,
              });
            });
            // 鼠标移出
            this.massMarks.on("mouseout", function (e) {
              marker.setPosition(e.data.lnglat);
              marker.setLabel({ content: "" });
            });
            //   if (this.isDraw) {
            //     let that = this
            //     that.massMarks.on('click', function (e) {
            //       if (e.data.style == 1) {
            //         e.data.style = 0
            //         that.parkIds.splice(that.parkIds.indexOf(e.data.id), 1);
            //       } else {
            //         e.data.style = 1
            //         that.parkIds.push(e.data.id)
            //       }
            //       that.massMarks.setStyle(style) // 必须加 不然海量点跟多边形区域冲突 不能实时更新图标，需要拖动地图
            //       that.$emit('setParkIds', that.parkIds)
            //     })
            //   }
            this.massMarks.setMap(this.map);
            // if (isInit) {
            if (this.type == "edit" && !this.isClear) {
              this.editMapInit();
            } else {
              this.areaMapInit();
            }
            // }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    editMapInit() {
      let that = this;
      if (that.isEditDraw) {
        that.addMarkerClick();
        return;
      }
      let pathList = that.paths;
      that.polygon = new AMap.Polygon({
        path: pathList,
        strokeColor: "#FF33FF",
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: "#1791fc",
        zIndex: 50,
      });
      that.map.add(that.polygon);
      // 缩放地图到合适的视野级别
      // that.map.setFitView([that.polygon])
      that.isClear = false;
      that.isEndEdit = false;
      that.polyEditor = new AMap.PolyEditor(that.map, that.polygon);
      // this.polyEditor.on('addnode', function (event) {
      //   // console.log('触发事件：addnode')
      // })
      that.polyEditor.open();
      that.isEditDraw = true;
      that.addMarkerClick();
    },
  },
  mounted() {
    this.map = new AMap.Map("map", {
      center: this.editCenter || [116.322056, 39.89491],
      expandZoomRange: true,
      zoom: 14,
      zooms: [10, 16],
    });
    if (this.type == "edit") {
      this.parksInProp = this.parks;
      this.isClear = false;
      this.parkIds = [...this.parkIds, ...this.parksInProp];
    }
    this.map.on("moveend", (e) => {
      this.massMarks.clear();
      this.getPark(
        Math.round(this.map.getCenter().lng * 1000000),
        Math.round(this.map.getCenter().lat * 1000000),
        false
      );
    });
    this.getPark(
      Math.round(this.map.getCenter().lng * 1000000),
      Math.round(this.map.getCenter().lat * 1000000),
      true
    );
  },
};
</script>

<style lang="stylus" scoped>
.mapWrapper >>> .amap-marker-label {
  border: 0;
  background-color: transparent;
}
.mapWrapper >>> .info {
  position: relative;
  top: 0;
  right: 0;
  min-width: 0;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: .25rem;
  background-color: white;
  width: auto;
  border-width: 0;
  box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
}
.mapWrapper {
  position relative;

  .search {
    position absolute;
    left 30px;
    top 30px;
    z-index 10;
  }

  .hotMap {
    height 500px;
  }

  .tool {
    position absolute;
    right 30px;
    bottom 30px;
    z-index 10;
  }
}
</style>
